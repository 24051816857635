import React, { Component } from "react";
import { setUser } from "../../actions/authActions";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import classnames from "classnames";

class UserSetting extends Component {

  constructor(props){
    super(props)
    this.getUserInfo()
    this.state = {
      _id: "",
      name: "123",
      email: "1@dfs.com",
      password: "",
      password2: "",
      errors: {},
      history: []
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.errors);
    if (nextProps.errors) {
      console.log(nextProps.errors);
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  update = e => {

    e.preventDefault();

    const UserInfo = {
      _id: this.state._id,
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2
    };

    this.props.setUser(UserInfo, this.props.history)
  }
  goBack = e => {

    this.props.history.push("/dashboard")
  }
  getUserInfo = () => {
    var self = this;
    var id = this.props.location.state
    if(!id){
      id = this.props.auth.user.id
    } 
    axios.post("/api/users/get_user_Info", {
      _id: id
    }).then(res => {
      self.setState({name: res.data.user.name})
      self.setState({email: res.data.user.email})
      self.setState({_id: res.data.user._id})
    })
    .catch(err => {
      console.log(err);
    }
    );
  }

  render() {

    const { errors } = this.state;
    const permission = this.props.auth.user.permission;
    return (
      <div>
        <div className="row" style={{marginTop: '100px'}}>
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <h2 className="text-center">User Setting</h2>
            <form noValidate onSubmit={this.onSubmit}>
              <div className="form-group col-md-12">
                <label htmlFor="name" className="active">Full Name</label>
                <input onChange={this.onChange} value={this.state.name} error={errors.name} className="form-control" id="name" type="text"/>
                <span className="text-danger">{errors.name}</span>
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="email" className="active">Username</label>
                <input onChange={this.onChange} value={this.state.email} error={errors.email} className="form-control" id="email" type="text"/>
                <span className="text-danger">{errors.email}</span>
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="password">Old Password</label>
                <input onChange={this.onChange} value={this.state.password} error={errors.password} className="form-control" id="password" type="password"/>
                <span className="text-danger">{errors.password}</span>
              </div> 
              <div className="form-group col-md-12">
                <label htmlFor="password2">New Password</label>
                <input onChange={this.onChange} value={this.state.password2} error={errors.password2} className="form-control" id="password2" type="password"/>
                <span className="text-danger">{errors.password2}</span>
              </div>
            </form>
            <div className="d-flex justify-content-start col-md-12 form-group">
              <button onClick={this.update} type="submit" className="btn btn-success">
                Update
              </button>
              <button onClick={this.goBack} type="submit" className="btn btn-danger" style={{marginLeft: '10px'}}>
                Cancel
              </button>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
        
      </div>
    );
  }
}

UserSetting.propTypes = {
  setUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { setUser }
)(withRouter(UserSetting));