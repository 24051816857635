import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import {Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { withRouter } from "react-router-dom";

class TopNavbar extends Component {

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  gotoUserSetting = e => {
    e.preventDefault();
    this.props.history.push('/user_setting');
  }
  constructor(props) {
    super(props);

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
        dropdownOpen: false
    };
  }

  toggleDropdown() {
    this.setState({
        dropdownOpen: !this.state.dropdownOpen
    });
  }

  render() {
    const { user } = this.props.auth;
    if(user.id){
      return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Navbar.Brand href="/dashboard" style={{fontFamily: 'Fredoka_One', fontSize: '20px'}}>Sample App</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/dashboard">Dashboard</Nav.Link>
            </Nav>
            <Nav>
            <NavDropdown alignRight title={user.name} id="basic-nav-dropdown">
              {user.permission == 1 ? <NavDropdown.Item eventKey="1" link="/user_setting" onClick={this.gotoUserSetting}>Setting</NavDropdown.Item> : null}
                <NavDropdown.Item eventKey="2" onClick={this.onLogoutClick}>Log out</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      );
    } else {
      return (<></>);
    }
    
  }
}

TopNavbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(TopNavbar));
