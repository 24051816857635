import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUserHistory, setUserActionByAdmin, deleteUserActionByAdmin, updateUserActionByAdmin } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import RClock from '../dashboard/clock'
import Dialog from '@material-ui/core/Dialog';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
// import { makeStyles, ThemeProvider } from "@material-ui/styles";
class Client extends Component {
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  constructor(props){
    super(props);
    this.state = {
      modalPro: true,
      actionId: null,
      selectedDate: new Date(),
      actionType: 1,
      open: false,
      amount: 1,
      history: {
        data: [],
      },
    };
  }
  changeAmount = e => {
    const userId = {
      _id: this.props.location.state._id,
      amount: parseInt(e.target.value)
    }
    this.setState({amount: e.target.value});
    const self = this;
    this.props.getUserHistory(self, userId);
  }
  
  UNSAFE_componentWillMount(){
    this.initUserHistory()
  }
  initUserHistory = () => {
    const userId = {
      _id: this.props.location.state._id,
      amount: this.state.amount
    }
    const self = this;
    this.props.getUserHistory(self, userId);
  }
  // modal function
  AddOpen = () => {
    this.setState({ modalPro: true , open: true});
  };
  EditOpen = (item) => {
    console.log(item.actionType)
    this.setState({ actionId: item._id, selectedDate: item.real_date, actionType: parseInt(item.action),  modalPro: false, open: true});
  };
  save = (e) => {
    e.preventDefault();
    const action = {
      action: this.state.actionType,
      _id: this.props.location.state._id,
      date: this.state.selectedDate,
    }
    var self = this
    console.log(this.state.modalPro)
    if(this.state.modalPro === true){
      this.props.setUserActionByAdmin(action, this.props.history, self)
    } else {
      action.actionId = this.state.actionId
      this.props.updateUserActionByAdmin(action, this.props.history, self)
    }
    this.initUserHistory()
  }
  DeleteAction = (item) => {
    console.log(item)
    const action = {
      _id: item._id,
    }
    var self = this
    this.props.deleteUserActionByAdmin(action, this.props.history, self)
    this.initUserHistory()
  }
  handleClose = () => {
    this.setState({open: false});
  };

  handleDateChange = (date) => {
    this.setState({selectedDate: date})
  };
  handleTimeChange = (date) => {
    console.log(date)
    this.setState({selectedDate: date})
    console.log(this.state.selectedDate)
  };
  actionChange = (e) => {
    this.setState({actionType: e.target.value})
  };
  
  // end modal function
  render() {
    const viewHistory = this.state.history.data.map((item, index) => {
      return(
        <tr key={index} style={item.actionType == 'Total days work:' ? {backgroundColor:'yellow'}: (item.actionType == 'Total week work:' ? {backgroundColor: 'green'} : null)}>
          <td>{item.date}</td>
          <td>{item.actionType}</td>
          <td style={item.actionType == 'Back From Break' ? {color: 'red'}: null}>{item.timeLapse}</td>
          <td className="text-center">
            {item.date !== '' ? <>
              <button onClick={this.EditOpen.bind(this, item)} className="btn btn-warning btn-sm"><i className="far fa-edit" style={{fontSize: '15px'}}></i> Edit Action</button> <button onClick={this.DeleteAction.bind(this, item)} className="btn btn-danger btn-sm"><i className="far fa-trash-alt" style={{fontSize: '15px'}}></i> Delete Action</button>
            </> : null }
          </td>
        </tr>
      )
    })
    return (
      <div className="landing-copy col s12 center-align">
        <div className="d-flex justify-content-center">
          <h1 className="text-center" style={{marginTop: '100px', fontFamily: 'Lobster', fontSize: '55px'}}>Time in </h1>
          <RClock></RClock>
          <h1 className="text-center" style={{marginTop: '100px', fontFamily: 'Lobster', fontSize: '55px'}}> Phoenix</h1>
        </div>
        <div>
        <MuiThemeProvider>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{this.state.modalPro == true ? "Add the new user action" : "Edit selected action"}</DialogTitle>
          <DialogContent>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Date picker dialog"
                format="MM/dd/yyyy"
                value={this.state.selectedDate}
                onChange={this.handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <br/>
              <br/>
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Select Action Time"
                value={this.state.selectedDate}
                onChange={this.handleTimeChange}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
              />
              <br/>
              <br/>
              <InputLabel id="demo-simple-select-label">Select Action Type</InputLabel>
              <Select
                labelid="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.actionType}
                onChange={this.actionChange}
                style={{width: '100%'}}
              >
                <MenuItem value={1}>Clock In</MenuItem>
                <MenuItem value={2}>Break</MenuItem>
                <MenuItem value={3}>Back from break</MenuItem>
                <MenuItem value={4}>Clock Out</MenuItem>
              </Select>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.save} color="secondary">
              Save
            </Button>
            <Button onClick={this.handleClose} color="inherit">
              Cancel
            </Button>
          </DialogActions>
              
          </Dialog>
        </MuiThemeProvider>
        </div>
        <div className="row">
          <div className="col-md-2">
          </div>
          <div className="col-md-8">
            <div>
              <div className="row">
                <div className="col-md-6">
                  <label style={{fontSize: 15, paddingRight: 10}}>Last </label>
                    <Select native defaultValue="" input={<Input id="grouped-native-select" />} onChange={this.changeAmount}>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                      <option value={9}>9</option>
                      <option value={10}>10</option>
                      <option value={18}>All</option>
                    </Select>
                  <label style={{fontSize: 15, paddingRight: 10}}> Actions</label>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <button onClick={this.AddOpen} className="btn btn-success btn-sm"><i className="fa fa-plus" style={{fontSize: '15px'}}></i> Add Action</button> 
                </div>
              </div>
            </div>
            <table className="table table-striped table-bordered" style={{width:'100%'}}>
              <thead>
                  <tr>
                      <th style={{width: '25%'}} className="text-center">Date and Time</th>
                      <th style={{width: '25%'}} className="text-center">Action</th>
                      <th style={{width: '25%'}} className="text-center">Time lapse</th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
                {viewHistory}
              </tbody>
          </table>
          </div>
          <div className="col-md-2">
          </div>
        </div>
      </div>
    );
  }
}

Client.propTypes = {
  auth: PropTypes.object.isRequired,
  setUserActionByAdmin: PropTypes.func.isRequired,
  deleteUserActionByAdmin: PropTypes.func.isRequired,
  updateUserActionByAdmin: PropTypes.func.isRequired,
  getUserHistory: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { getUserHistory, setUserActionByAdmin, deleteUserActionByAdmin, updateUserActionByAdmin }
)(withRouter(Client));