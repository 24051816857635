import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setUserAction, getUserHistory } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import RClock from './clock'

class Client extends Component {
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  constructor(props){
    super(props);
    this.state = {
      amount: 1,
      history: {
        data: [],
      },
    };
  }
  changeAmount = e => {
    const userId = {
      _id: this.props.auth.user.id,
      amount: parseInt(e.target.value)
    }
    this.setState({amount: e.target.value});
    const self = this;
    this.props.getUserHistory(self, userId);
  }
  
  UNSAFE_componentWillMount(){
    this.initUserHistory()
  }
  initUserHistory = () => {
    const userId = {
      _id: this.props.auth.user.id,
      amount: this.state.amount
    }
    const self = this;
    this.props.getUserHistory(self, userId);
  }
  onClockIn = e => {
    e.preventDefault();
    this.setState({state: 1});
    const action = {
      action: 1,
      _id: this.props.auth.user.id,
      id: this.props.auth.user.id,
      state: this.props.auth.user.status,
      permission: this.props.auth.user.permission,
      amount: this.state.amount
    }
    this.props.setUserAction(action, this);
  }
  onBreak = e => {
    e.preventDefault();
    this.setState({state: 2})
    const action = {
      action: 2,
      _id: this.props.auth.user.id,
      amount: this.state.amount
    }
    this.props.setUserAction(action, this);
  }
  onBackFromBreak = e => {
    e.preventDefault();
    this.setState({state: 3})
    const action = {
      action: 3,
      _id: this.props.auth.user.id,
      amount: this.state.amount
    }
    this.props.setUserAction(action, this);
  }
  onClockOut = e => {
    e.preventDefault();
    this.setState({state: 4})
    const action = {
      action: 4,
      _id: this.props.auth.user.id,
      amount: this.state.amount
    }
    this.props.setUserAction(action, this);
  }
  render() {
    const viewHistory = this.state.history.data.map((item, index) => {
      return(
        <tr key={index} style={item.actionType == 'Total days work:' ? {backgroundColor:'yellow'}: (item.actionType == 'Total week work:' ? {backgroundColor: 'green'} : null)}>
          <td>{item.date}</td>
          <td>{item.actionType}</td>
          <td style={item.actionType == 'Back From Break' ? {color: 'red'}: null}>{item.timeLapse}</td>
        </tr>
      )
    })
    return (
      <div className="landing-copy col s12 center-align" style={{marginBottom: '50px'}}>
        <div className="d-flex justify-content-center">
          <h1 className="text-center" style={{marginTop: '100px', fontFamily: 'Lobster', fontSize: '55px'}}>Time in </h1>
          <RClock></RClock>
          <h1 className="text-center" style={{marginTop: '100px', fontFamily: 'Lobster', fontSize: '55px'}}> Phoenix</h1>
        </div>
        <div className="row">
          <div className="col-md-2">
          </div>
          <div className="col-md-8">
          <div>
            <div className="row">
              <div className="col-md-6">
                <label style={{fontSize: 15, paddingRight: 10}}>Last </label>
                <Select native defaultValue="" input={<Input id="grouped-native-select" />} onChange={this.changeAmount}>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={18}>All</option>
                </Select>
                <label style={{fontSize: 15, paddingRight: 10}}> Actions</label>
              </div>
            </div>
            
            </div>
            <table className="table table-striped table-bordered" style={{width:'100%'}}>
                <thead>
                    <tr>
                        <th>Date and Time</th>
                        <th>Action</th>
                        <th>Time lapse</th>
                    </tr>
                </thead>
                <tbody>
                  {viewHistory}
                </tbody>
            </table>
          </div>
          <div className="col-md-2">
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
          </div>
          <div className="col-md-8 d-flex justify-content-around">
              <button onClick={this.onClockIn} className="btn btn-primary" disabled={this.state.state === 4 || this.state.state === 0 ? false : true }>
                Clock In
              </button>
              <button onClick={this.onBreak} className="btn btn-primary" disabled={this.state.state === 1 || this.state.state === 3 || this.state.state === 0 ? false : true}>
                Break
              </button>
              <button onClick={this.onBackFromBreak} className="btn btn-primary" disabled={this.state.state === 2 || this.state.state === 0 ? false : true}>
                Back from break
              </button>
              <button onClick = {this.onClockOut} className="btn btn-primary" disabled={this.state.state === 0 || this.state.state === 1 || this.state.state === 2 || this.state.state === 3 ? false : true}>
                Clock Out
              </button>
          </div>
          <div className="col-md-2">
          </div>
        </div>
        <div>
        </div>
      </div>
    );
  }
}

Client.propTypes = {
  auth: PropTypes.object.isRequired,
  setUserAction: PropTypes.func.isRequired,
  getUserHistory: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { setUserAction, getUserHistory }
)(withRouter(Client));