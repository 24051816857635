import axios from "axios";



// Register User
export const setUserAction = (action, self) => dispatch => {
  axios.post("/api/actions/addAction", action)
    .then(res => {
      self.setState({history: res.data});
      if(res.data.actionSuccess === false && res.data.data && res.data.data.length>0) {
        self.setState({state: parseInt(res.data.data[0].action)});
      }
      return {
        type: 'SET_CURRENT_USER_ACTION',
        data: {action: action}
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const setUserActionByAdmin = (action, history, self) => dispatch => {
  axios.post("/api/actions/addActionByAdmin", action)
    .then(res => {
      self.setState({open: false})
      return {
        type: 'SET_USER_ACTION_BY_ADMIN',
        data: {action: action}
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const deleteUserActionByAdmin = (action, history, self) => dispatch => {
  axios.post("/api/actions/deleteActionByAdmin", action)
    .then(res => {
      self.setState({open: false})
      return {
        type: 'DELETE_USER_ACTION_BY_ADMIN',
        data: {action: action}
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const updateUserActionByAdmin = (action, history, self) => dispatch => {
  axios.post("/api/actions/updateActionByAdmin", action)
    .then(res => {
      self.setState({open: false})
      return {
        type: 'UPDATE_USER_ACTION_BY_ADMIN',
        data: {action: action}
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const getUserHistory = (self, userId) => dispatch => {
  axios.post("/api/actions/getUserHistory", userId)
    .then(res => {
      self.setState({history: res.data})
        if(res.data.data && res.data.data.length>0) {
          self.setState({state: parseInt(res.data.data[0].action)});
        } else if(res.data.actionType){
          self.setState({state: parseInt(res.data.actionType)});
        } else {
          self.setState({state: 4});
        }
    })
    .catch(err => {
      console.log(err)
    });
};