import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { roleTemplate } from "../../actions/authActions";
import Select from '@material-ui/core/Select';
import axios from "axios";
class Template extends Component {
  constructor() {
    super();
    this.getRoleInfo()	  
    this.state = {
      template1: "",
      template2: "",	    
      errors: {}
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    // if (this.props.auth.user.permission != 1) {
      // this.props.history.push("/dashboard");
    // }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
 actionChange = (e) => {
    this.setState({template1: e.target.value})
  };
  actionChange1 = (e) => {
    this.setState({template2: e.target.value})
  };	
  onSubmit = e => {
    e.preventDefault();

    const newUser = {
      template1: this.state.template1,
      template2: this.state.template2	    

    };

    this.props.roleTemplate(newUser, this.props.history);
  };
  getRoleInfo = () => {
    var self = this;
    
    axios.post("/api/users/roleInfo").then(res => {
      self.setState({template1: res.data.TemplateType1})
      self.setState({template2: res.data.TemplateType2})
    })
    .catch(err => {
      console.log(err);
    }
    );
  }	  

  render() {
    const { errors } = this.state;

    return (
      <div className="row" style={{marginTop: '100px'}}>
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <h2 className="">Schedule && Job Code</h2><br />
          <form noValidate onSubmit={this.onSubmit}>
	    	   <div class="form-group col-md-12">
<label htmlFor="role" className="active"><h4>Job Code</h4></label>
<label htmlFor="role" className="active" style={{marginLeft: '38%'}}><h4>Schedule</h4></label>
</div>
	    <div class="form-group col-md-12">
<label htmlFor="role" className="active">Baler</label>
<Select value={this.state.template1} id="template1" onChange={this.actionChange}  style={{marginLeft: '47%'}}>
                  <option value={1}>6:30AM-12:30PM Mon-Fri</option>
                  <option value={2}>1:00AM-3:00PM Mon-Fri</option>
                </Select>
</div>

<div class="form-group col-md-12">
<label htmlFor="role" className="active">Mover</label>
<Select value={this.state.template2} id="template2" onChange={this.actionChange1}  style={{marginLeft: '46%'}}>
                  <option value={1}>6:30AM-12:30PM Mon-Fri</option>
                  <option value={2}>1:00AM-3:00PM Mon-Fri</option>
                </Select>
</div>
            <div className="form-group col-md-12">
              <button onClick={this.onSubmit} type="submit" className="btn btn-success">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Template.propTypes = {
  roleTemplate: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { roleTemplate }
)(withRouter(Template));
