import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { TableBody, TableRow, TableCell, TableHead, Table } from '@material-ui/core';
import axios from "axios";
import { Link, withRouter } from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


class Manager extends Component {
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  constructor (props) {
    super(props);
    this.getUsers()
    this.state = {
      users: [],
      openDeleteDialog: false,
      seletctedUser: null,
      deleteButton: 'Delete'
    }
  }

  getUsers = () => {
    var self = this;
    axios.get("/api/users/get_user_list")
    .then(res => {
      self.setState({users: res.data.users})
    })
    .catch(err => {
      console.log(err);
    }
    );
  }

  handleOpenDeleteDialog = (seletctedUser) => {
    this.setState({openDeleteDialog: true, deleteButton: 'Delete', seletctedUser});
  }

  handleCloseDeleteDialog = () => {
    this.setState({openDeleteDialog: false, seletctedUser: null});
  }

  deleteUser = () => {
    if (this.state.seletctedUser === null) {
      return false;
    }
    this.setState({deleteButton: 'Delete in progress'});
    axios.post("/api/users/delete", {
      _id: this.state.seletctedUser._id
    }).then( () => {
      this.state.users.splice(this.state.users.indexOf(this.state.seletctedUser), 1);
      this.handleCloseDeleteDialog();
      // this.getUsers()
    }).catch( () => {
      this.setState({deleteButton: 'Unable to delete!'});
      setTimeout( () => {
        this.setState({deleteButton: 'Delete'});
      }, 2000);
    });
  }

  render() {

    return (
        <div className="landing-copy col-md-12 center-align">
          <div style={{marginTop: 100}} className="row justify-content-around">
            <div>
              <h3 style={{fontFamily: 'Carter_One'}}>Regular Users</h3>
            </div>
            <div>
              <Link className="btn btn-primary btn-xs waves-effect" to="/add_user">Add User</Link>
	      <Link className="btn btn-primary btn-xs waves-effect" to="/schedule" style={{marginLeft:'5px',marginRight:'20px' }}>Manage Schedule/Job Code</Link>
            </div>
          </div>
          <div>
          </div>
          <div className="row">
            <div className="col-md-2">
            </div>
            <div className="col-md-8">
              <Table style={{width: '100%'}} className="table-striped table-bordered table-responsive-md btn-table" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{fontSize: '18px', textAlign: 'center'}}>No</TableCell>
                    <TableCell style={{fontSize: '18px', textAlign: 'center'}}>User</TableCell>
                    <TableCell style={{fontSize: '18px', textAlign: 'center'}}>Role</TableCell>
                    <TableCell style={{fontSize: '18px', textAlign: 'center', width: '30%'}}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.users.map((n, index) => {
                    return (
                    <TableRow key={n._id}>
                      <TableCell style={{fontSize: '18px', textAlign: 'center'}}>{index + 1 }</TableCell>
                      <TableCell style={{fontSize: '18px', textAlign: 'center'}}>{n.name}</TableCell>
                      <TableCell style={{fontSize: '18px', textAlign: 'center'}}>{n.permission === 2 ? "Supervisor" : "Regular User"}</TableCell>
                      <TableCell style={{fontSize: '18px', textAlign: 'center'}} style={{ textAlign: 'center' }}>
                        <ManageButton user={n} history={this.props.history} getUsers={this.getUsers} handleOpenDeleteDialog={this.handleOpenDeleteDialog}/>
                      </TableCell>
                    </TableRow>
                    )
                  }
                    )}
                </TableBody>
              </Table>
              <div>
        <MuiThemeProvider>
        <Dialog open={this.state.openDeleteDialog} onClose={this.handleCloseDeleteDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Delete User</DialogTitle>
          <DialogContent>
                Would you like to delete user <u><strong>{this.state.seletctedUser && this.state.seletctedUser.name}</strong></u>? There is no <strong>undo</strong> option once user deleted.
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.deleteUser} color="secondary">
              {this.state.deleteButton}
            </Button>
            <Button onClick={this.handleCloseDeleteDialog} color="inherit">
              Cancel
            </Button>
          </DialogActions>
              
          </Dialog>
        </MuiThemeProvider>
        </div>
            </div>
          </div>
        </div>
    );
  }
}

class ManageButton extends Component {

  

  render(){
    return (
      <div>
        <Link className="btn btn-primary btn-xs waves-effect" to={{
          pathname: '/user_history',
          state: {
            _id: this.props.user._id
        }}}>View History</Link>
        <Link className="btn btn-warning btn-xs waves-effect" style={{marginLeft: 10, marginRight: 10}} to={{
        pathname: '/update_user_setting',
        state: {
          _id: this.props.user._id
        }}}>Update</Link>
        <button className="btn btn-danger btn-xs waves-effect" onClick={() => this.props.handleOpenDeleteDialog(this.props.user)}>Delete</button>
      </div>
    );
  }
}

Manager.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users
});

export default withRouter( connect(  mapStateToProps,  { logoutUser })(Manager));
