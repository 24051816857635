import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      checked: false
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password,
      checked: this.state.checked
    };

    this.props.loginUser(userData);
  };
  handleChange = e => {
    this.setState({...this.state, checked: !this.state.checked});
  }
  render() {
    const { errors } = this.state;

    return (
      <div >
        <section className="login-block">
          <div className="container"  style={{width: '20%'}}>
            <div className="row">
              <div className="col-md-12 login-sec">
                  <h2 className="text-center">Login Now</h2>
                  <div class="custom-control custom-checkbox">
                      <input onChange={this.handleChange} type="checkbox" className="custom-control-input" id="defaultUnchecked" />
                      <label className="custom-control-label" for="defaultUnchecked">I am admin</label>
                  </div>
                  <form noValidate onSubmit={this.onSubmit}>
                    <div className="form-group">
                      <label htmlFor="email">Digits</label>
                      <input onChange={this.onChange} value={this.state.email} error={errors.email} id="email" type="text" className="form-control"/>
                      <span className="text-danger">
                        {errors.email}
                        {errors.emailnotfound}
                      </span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input disabled = {this.state.checked == true ? false : true} onChange={this.onChange} value={this.state.password} error={errors.password} id="password" type="password" className="form-control"/>
                      <span className="text-danger">
                        {errors.password}
                        {errors.passwordincorrect}
                      </span>
                    </div>
                    <div className="form-check">
                      <button type="submit" className="btn btn-login float-right">Login</button>
                    </div>
                  </form>
              </div>
              </div>
            </div>
          </section>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
