import React, { Component } from "react";
import { updateUser } from "../../actions/authActions";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Select from '@material-ui/core/Select';
class UserSetting extends Component {

  constructor(props){
    super(props)
    this.getUserInfo()
    this.state = {
      _id: "",
      name: "123",
      email: "1@dfs.com",
      role: "Baler",	    
      errors: {},
      history: []
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.errors);
    if (nextProps.errors) {
      console.log(nextProps.errors);
      this.setState({
        errors: nextProps.errors
      });
    }
  }
  actionChange = (e) => {
    this.setState({role: e.target.value})
  };
  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  update = e => {
    e.preventDefault()
    const UserInfo = {
      _id: this.state._id,
      name: this.state.name,
      email: this.state.email,
      role: this.state.role,	    
    };
    this.props.updateUser(UserInfo, this.props.history)
  }
  goBack = e => {
    this.props.history.push("/dashboard")
  }
  getUserInfo = () => {
    var self = this;
    var id = this.props.location.state
    if(!id){
      id = this.props.auth.user.id
    } 
    axios.post("/api/users/get_user_Info", {
      _id: id
    }).then(res => {
      self.setState({name: res.data.user.name})
      self.setState({email: res.data.user.email})
      self.setState({_id: res.data.user._id})
       self.setState({role: res.data.user.role})	    
    })
    .catch(err => {
      console.log(err);
    }
    );
  }

  render() {

    const { errors } = this.state;
    const permission = this.props.auth.user.permission;
    console.log(permission)
    return (
      <div className="row" style={{marginTop: '100px'}}>
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <h2 className="text-center">User Setting</h2>
          <form noValidate onSubmit={this.onSubmit}>
            <div className="form-group col-md-12">
              <label htmlFor="name" className="active">Full Name</label>
              <input onChange={this.onChange} value={this.state.name} error={errors.name} id="name" type="text"className="form-control" />
              <span className="text-danger">{errors.name}</span>
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="email" className="active">Username</label>
              <input onChange={this.onChange} value={this.state.email} error={errors.email} id="email" type="text" className="form-control" />
              <span className="text-danger">{errors.email}</span>
            </div>
	    <div className="form-group col-md-12">
              <label htmlFor="role" className="active">Select Role</label> <br />
              <Select value={this.state.role} id="role" onChange={this.actionChange}>
                  <option value={'Baler'}>Baler</option>
                  <option value={'Mover'}>Mover</option>
                </Select>
            </div>
          </form>
            <div className="form-group col-md-12">
              <button onClick={this.update} type="submit" className="btn btn-success">
                Update
              </button>
              <button onClick={this.goBack} type="submit" className="btn btn-danger" style={{marginLeft: '10px'}}>
                Cancel
              </button>
            </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    );
  }
}

UserSetting.propTypes = {
  setUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { updateUser }
)(withRouter(UserSetting));
